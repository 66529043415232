<template>
  <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm" v-loading="loading" size="default">
    <el-form-item label="IP名称" prop="name">
      <el-input v-model="ruleForm.name"  style="width:360px" placeholder="请输入IP名称"></el-input>
    </el-form-item>
    <el-form-item label="IP logo" prop="logo">
      <div class="ipLogo">
          <div class="logo">
            <img :src="ruleForm.logo">
          </div>
          <div @click="uploadPicture('logo')">
            <div class="upload">上传</div>
            <p>请上传.png或.jpg图片</p>
          </div>
      </div>
    </el-form-item>
    <el-form-item label="IP横版配图" prop="horizontal_img">
      <div class="ipLogo">
          <div class="logo">
            <img :src="ruleForm.horizontal_img">
          </div>
          <div @click="uploadPicture('horizontal_img')">
            <div class="upload">上传</div>
            <p>请上传.png或.jpg图片</p>
          </div>
      </div>
    </el-form-item>
    <el-form-item label="IP竖版配图" prop="vertical_img">
      <div class="ipLogo">
          <div class="logo">
            <img :src="ruleForm.vertical_img">
          </div>
          <div @click="uploadPicture('vertical_img')">
            <div class="upload">上传</div>
            <p>请上传.png或.jpg图片</p>
          </div>
      </div>
    </el-form-item>
    <el-form-item label="所属区域" prop="area">
      <el-radio-group v-model="ruleForm.area">
        <el-radio :label=1>国内</el-radio>
        <el-radio :label=2>国外</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item label="内容类型" prop="ipType">
      <el-cascader
        expand-trigger="hover"
        :options="ipTypeOption"
        v-model="ruleForm.ipType"
        @change="handleChange"
        style="width:360px"
        placeholder="请选择内容类型"
        >
      </el-cascader>
    </el-form-item>
    <el-form-item label="合作内容" prop="cooperation_type">
       <el-select 
        v-model="ruleForm.cooperation_type" 
        multiple
        placeholder="请选择合作内容" collapse-tags 
        style="width:360px"
       >
          <el-option
            v-for="item in cooperationOption"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
    </el-form-item>
    <el-form-item label="合作价格">
      <el-col :span="4">
        <el-form-item prop="cooperation_price_down">
          <el-input-number v-model="ruleForm.cooperation_price_down" :max="ruleForm.cooperation_price_up" :controls="false" :min="0"></el-input-number>
        </el-form-item>
      </el-col>
      <el-col class="line" :span="1"> {{'-'}}</el-col>
      <el-col :span="4">
        <el-form-item prop="cooperation_price_up">
          <el-input-number v-model="ruleForm.cooperation_price_up"  :min="ruleForm.cooperation_price_down" :controls="false"></el-input-number>
        </el-form-item>
      </el-col>
    </el-form-item>
    <el-form-item label="简介" prop="desc">
      <el-input type="textarea" v-model="ruleForm.desc" style="width:648px" placeholder="请输入IP简介"></el-input>
    </el-form-item>
    <el-form-item label="标签" prop="tags">
      <el-tag
        :key="tag"
        v-for="tag in ruleForm.tags"
        closable
        :disable-transitions="false"
        @close="handleClose(tag)"
        class="tag">
        {{tag.name}}
        
      </el-tag>
      <el-button class="newTag" @click="tagsChange">+新增标签</el-button>
    </el-form-item>
    <el-form-item label="付款形式" prop="pay_type">
       <el-select v-model="ruleForm.pay_type" placeholder="请选择付款形式"  style="width:360px">
          <el-option
            label="刊前"
            :value="1">
          </el-option>
          <el-option
            label="刊后"
            :value="2">
          </el-option>
        </el-select>
    </el-form-item>
    <el-form-item label="物料审核周期" prop="material_audit_range">
      <el-select v-model="ruleForm.material_audit_range" placeholder="请选择物料审核周期"  style="width:360px">
          <el-option
            label="一周"
            :value="1">
          </el-option>
          <el-option
            label="两周"
            :value="2">
          </el-option>
          <el-option
            label="一个月"
            :value="3">
          </el-option>
          <el-option
            label="两个月"
            :value="4">
          </el-option>
          <el-option
            label="三个月及以上"
            :value="5">
          </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="合作周期" prop="cooperation_range">
      <el-select v-model="ruleForm.cooperation_range" placeholder="请选择合作周期"  style="width:360px" multiple collapse-tags>
          <el-option
            label="一个月"
            value="1">
          </el-option>
          <el-option
            label="三个月"
            value="2">
          </el-option>
          <el-option
            label="六个月"
            value="3">
          </el-option>
          <el-option
            label="一年之内"
            value="4">
          </el-option>
          <el-option
            label="一年以上"
            value="5">
          </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="合作目的" prop="cooperation_purpose">
      <el-select v-model="ruleForm.cooperation_purpose" placeholder="请选择合作目的"  style="width:360px" multiple collapse-tags>
        <el-option
          label="品牌"
          value="1">
        </el-option>
        <el-option
          label="效果"
          value="2">
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item>
      <div class="formConfirm">
        <el-button type="primary" @click="submitForm(1)" class="btn" size="large">去完善IP介绍</el-button>
        <el-button @click="submitForm(2)" class="btn" size="large">先保存稍后完善</el-button>
      </div>
    </el-form-item>
  </el-form>
  <el-dialog
    title="图片裁剪"
    v-model="cropperModel"
    center
    width='90%'
    :key="kind"
    :close-on-click-modal=false
    >
    <cropper-image
        accept="image/png,image/jpg"
        :config="config[kind]"
        :kind="kind"
        @uploadImgSuccess = "handleUploadSuccess"
        ref="child"
        >
    </cropper-image>
  </el-dialog>
  <el-dialog
    title="新增标签"
    v-model="tagsModal"
    :close-on-click-modal=false
    width="992px"
  >
    <div class="modalContent">
    <el-checkbox-group v-model="checkedListId">
      <el-checkbox :label="item.id" v-for="item in tagList" :key="item.id" class="tags">{{tagList.filter
        (v=> v.id === item.id)[0].name}}</el-checkbox>
    </el-checkbox-group>
    <div class="footer">
      <el-button type="primary" @click="tagConfirm" class="confirm">确认</el-button>
    </div>
    </div>
  </el-dialog>
</template>
<script>
import cropperImage from "@/components/cropperImage.vue";
import tracker from '@/common/tracker.js';
export default {
		data() {
      return {
        id:this.iid,
        loading:false,
        logoFile:{},
        //裁切图片参数
        cropperModel:false,
        cropperName:'',
        kind:'',
        config:{
          'logo':{
            autoCropWidth:"200",
            autoCropHeight:"200",
          },
          'horizontal_img':{
            autoCropWidth:"520",
            autoCropHeight:"300"
          },
          'vertical_img':{
            autoCropWidth:"212",
            autoCropHeight:"260"
          },
        },
        ruleForm: {
          area:1,
        },
        cooperationOption:[],
        ipTypeOption:[],
        tagsModal:false,
        tagListTotal:[],
        tagList:[],
        checkedListId:[],
        rules: {
          name: [
            { required: true, message: '请输入IP名称', trigger: 'blur' },
            { max: 50, message: 'IP名称超长，限制50个字符', trigger: 'blur' }
          ],
          logo: [
            { required: true, message: '请上传IP Logo'},
          ],
          horizontal_img: [
            { required: true, message: '请上传IP 横版配图'},
          ],
          vertical_img: [
            { required: true, message: '请上传IP 竖版配图'},
          ],
          area:[
            { required: true, message: '请选择所属区域'},
          ],
          ipType:[
            { required: true, message: '请选择内容类型'},
          ],
          cooperation_type:[
            { required: true, message: '请选择合作内容',trigger: 'blur'},
          ],
          desc: [
            { required: true, message: '请输入IP简介', trigger: 'blur' },
            { max: 300, message: 'IP简介超长，不得超出300个字符', trigger: 'blur' }
          ],
          tags:[
            { required: true, message: '请至少选择一个标签'},
          ],
          pay_type:[
            { required: true, message: '请选择付款形式'},
          ],
          material_audit_range:[
            { required: true, message: '请选择物料审核周期'},
          ],
          cooperation_range:[
            { required: true, message: '请选择合作周期',trigger: 'blur'},
          ],
          cooperation_purpose:[
            { required: true, message: '请选择合作目标',trigger: 'blur'},
          ],
          region: [
            { required: true, message: '请选择活动区域', trigger: 'change' }
          ],
          date1: [
            { type: 'date', required: true, message: '请选择日期', trigger: 'change' }
          ],
          date2: [
            { type: 'date', required: true, message: '请选择时间', trigger: 'change' }
          ],
          type: [
            { type: 'array', required: true, message: '请至少选择一个活动性质', trigger: 'change' }
          ],
          resource: [
            { required: true, message: '请选择活动资源', trigger: 'change' }
          ],
        }
      };
    },
    components: {
      cropperImage
    },
    props:['iid'],
		methods:{ 
			submitForm(type) {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.API.save_ip({
              id:this.id,
              ...this.ruleForm,
              ip_type_level1:this.ruleForm.ipType[0],
              ip_type_level2:this.ruleForm.ipType[1],
              tags:this.ruleForm.tags.map(v=> {return v.id}),
            }, (res) => { 
              const { data } = res
              this.id = data
              if(type === 1){
                tracker({opType:11,ipId:data})
                this.$router.push(`/person/management/add?iid=${data}`)
                this.$emit('changeActive','introduce',data)
              }else{
                this.$router.push('/person/management');
                tracker({opType:14,ipId:data})
              }
            })
          }
        });
      },
      async getData(){
        this.loading = true;
				await this.API.ip_detail_user({id:this.id}, (res) => { 
					const { data} = res
					this.ruleForm = {...data,ipType:[data.ip_type_level1,data.ip_type_level2]};
          this.checkedListId = data.tags.map(v=> v.id)
				})
        this.loading = false;
			},
      getIpType(){
        this.API.ip_type_dict({}, (res) => { 
					const { data} = res
					this.ipTypeOption = data
				})
      },
      getCooperationType(){
        this.API.coop_type_dict({}, (res) => { 
					const { data} = res
					this.cooperationOption = data
				})
      },
      getTags(){
        this.API.tag_dict({}, (res) => { 
					const { data } = res
					this.tagListTotal = data
          this.tagList = data
				})
      },
      //封面设置
      uploadPicture(kind){
        this.kind = kind
        this.cropperModel = true;
      },
      //图片截图成功后
      handleUploadSuccess ({base64,kind}){
        this.cropperModel = false;
        this.ruleForm[kind] = base64
      },
      //内容类型
      handleChange(){
        this.ruleForm.tags = []
        this.checkedListId = []
      },
      //标签删除
      handleClose(tag) {
        this.ruleForm.tags.splice(this.ruleForm.tags.indexOf(tag), 1);
        this.checkedListId =  this.ruleForm.tags.map(v=>v.id)
      },
      tagConfirm(){
        if(this.checkedListId.length>10){
          this.$message({
            type: 'info',
            message: '最多选择十个标签!'
          });
          return
        }
        this.tagsModal = false
        const setIds = new Set(this.checkedListId)
        this.ruleForm.tags = this.tagList.filter(v=> setIds.has(v.id) )
      },
      //标签弹窗
      tagsChange(){
        this.tagsModal = true
        this.checkedListId = this.ruleForm.tags.map(v=>v.id)
      },
		},
		created(){
			if(this.id){
        this.getData()
      }
      this.getIpType()
      this.getCooperationType()
      this.getTags()
		},
    watch:{
      'ruleForm.ipType':function () {
        this.tagList = this.tagListTotal.filter((v)=> v.type_id === this.ruleForm.ipType[0])
      },
      'tagListTotal':function () {
        this.tagList = this.tagListTotal.filter((v)=> v.type_id === this.ruleForm.ipType?.[0])
      }
    }
	}
</script>
<style lang="less" scoped="" src='./styles.less'>
</style>