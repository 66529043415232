<template>
  <div class="horizontal">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
      <el-form-item label="显示顺序" prop="sort">
        <el-input v-model="ruleForm.sort" type="number" style="max-width: 360px"></el-input>
      </el-form-item>
      <el-form-item label="栏目名称" prop="column_name">
        <el-input v-model="ruleForm.column_name" style="max-width: 360px"></el-input>
      </el-form-item>
      <div  class="formContent" v-for="(content, index) in ruleForm.content" :key="content">
        <div class="delete" @click="removeContent(content)">删除</div>
        <el-form-item label="内容标题" :prop="`content.${index}.title`" :rules="rules.title">
          <el-input v-model="content.title" style="max-width: 360px"></el-input>
        </el-form-item>
        <el-form-item label="内容描述" :prop="`content.${index}.content`" :rules="rules.content">
          <el-input type="textarea" v-model="content.content" style="max-width: 648px"></el-input>
        </el-form-item>
        <el-form-item label="配图" :prop="`content.${index}.image`" :rules="rules.image">
          <div class="ipLogo">
              <div class="logo">
                <img :src="content?.image">
              </div>
              <div @click="uploadPicture(index)">
                <div class="upload">上传</div>
                <p>请上传.jpg、.jpeg或.png图片</p>
              </div>
          </div>
        </el-form-item>
      </div>
      <div class="add" @click="addContent">+新增一条图文列表详情</div>
      <el-form-item>
        <div class="formConfirm">
          <el-button type="primary" @click="submitForm">保存</el-button>
          <el-button @click="cancel">取消</el-button>
        </div>
      </el-form-item>
    </el-form>
    <el-dialog
      title="图片裁剪"
      v-model="cropperModel"
      center
      width='90%'
      :key="index+type" 
      :close-on-click-modal=false
      >
      <cropper-image
          accept="image/png,image/jpg,image/jpeg"
          :config="config[type]"
          :kind="index"
          @uploadImgSuccess = "handleUploadSuccess"
          >
      </cropper-image>
    </el-dialog>
  </div>
</template>
<script>
import cropperImage from "@/components/cropperImage.vue";
export default {
		data() {
      return {
        //裁切图片参数
        cropperModel:false,
        index:0,
        config:{
          '1':{
            autoCropWidth:"270",
            autoCropHeight:"160"
          },
          '5':{
            autoCropWidth:"212",
            autoCropHeight:"260"
          },
        },
        ruleForm: {
          sort:(this.number+1)*10,
          ...this.ruleFormEdit,
          content:this.ruleFormEdit?.content?.length > 0 ? this.ruleFormEdit?.content:[{}],
        },
        rules: {
          sort: [
            { required: true, message: '请填写显示顺序', trigger: 'blur' },
          ],
          column_name: [
            { required: true, message: '请填写栏目名称',trigger: 'blur'},
            { max:20,message:'栏目名称不能超过20个字符',trigger: 'blur'}
          ],
          title: [
            { required: true, message: '请填写内容标题',trigger: 'blur'},
            { max:50, message:'内容标题不得超出 50个字符',trigger: 'blur'}
          ],
          content: [
            { required: true, message: '请填写内容描述',trigger: 'blur'},
            { max:500,message:'内容描述不得超出500个字符',trigger: 'blur'}
          ],
          image:[
            { required: true, message: '请上传内容配图'},
          ]
        }
      };
    },
    props:['ruleFormEdit','number','type','ipId'],
    components: {
      cropperImage
    },
		methods:{ 
			submitForm() {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.API.save_ip_introduction({ipId:this.ipId,...this.ruleForm,type:this.type}, (res) => { 
              const { code } = res
              if(code === 0){
                 this.$emit('ok')
              }else{
                this.$message({
                  type: 'info',
                  message: msg
                });
              }
            })
          }
        });
      },
      cancel(){
        this.$emit('cancel')
      },
      //封面设置
      uploadPicture(index){
        this.index = index
        this.cropperModel = true;
      },
      //图片截图成功后
      handleUploadSuccess ({base64,kind}){
        this.cropperModel = false;
        this.ruleForm.content = this.ruleForm.content.map((v,_index)=>{
          if(_index === kind){
            v.image = base64
          }
          return v
        })
      },
      removeContent(item){
        const content = this.ruleForm.content
        if(content.length <=1){
          return
        }
        const index = content.indexOf(item)
        if (index !== -1) {
          content.splice(index, 1)
        }
      },
      addContent(){
        this.ruleForm.content.push({})
      }
		},
	}
</script>
<style lang="less" scoped="" src='./styles.less'>
</style>