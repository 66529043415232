<template>
  <div class="wrap">
    <p v-if="showTitle" class="title">
      <img src="@/assets/icon/icon_dynamic.png" />
      {{ data.name }}
    </p>
    <div class="type">
      {{ dynamic_config[data.type].label
      }}{{
        +data.works_type ? `-${works_type_config[data.works_type].label}` : ""
      }}
      <span></span>
      {{ date_text_config[data.type] }}：{{ formatDate(data.start_date)
      }}{{ data.end_date ? `-${formatDate(data.end_date)}` : "" }}
    </div>
    <div class="desc">
      <p>{{ data.introduction }}</p>
      <p>
        {{ data.cooperation_opportunities }}
      </p>
    </div>
    <div v-if="!!data.end_date_of_attract_investment" class="date" :style="{ marginBottom: isShowPageBtn ? '0px' : '24px' }">
      招商截止时间：{{ formatDate(data.end_date_of_attract_investment)  }}
    </div>
    <div v-if="isShowPageBtn && data?.images_list?.length > 0 " class="img_list_page">
      <span
        :class="{ pagination_pre: true, pagination_disable: leftBtnDisable }"
        @click="!leftBtnDisable && carousel('left')"
        ><el-icon><ArrowLeftBold /></el-icon
      ></span>
      <span
        :class="{ pagination_next: true, pagination_disable: rightBtnDisable }"
        @click="!rightBtnDisable && carousel('right')"
        ><el-icon><ArrowRightBold /></el-icon
      ></span>
    </div>
    <div class="img_list" v-if="data.images_list?.length > 0" ref="imgListRef">
      <div
        class="img_list_wrap"
        ref="imgListWrapRef"
        :style="{ left: -scrollLeft + 'px' }"
      >
        <div class="img_list_item" v-for="(imgItem, i) in data.images_list" :key="i">
          <el-image
            :src="imgItem"
            :initial-index="i"
            :preview-src-list="data.images_list"
            fit="cover"
            ref="elImageRef"
            close-on-press-escape
            hide-on-click-modal
          />
          <div class="img_list_mark" @click="imgClick(i)">
            <img src="@/assets/icon/icon_see.png" />
            点击查看原图
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ArrowRightBold, ArrowLeftBold } from "@element-plus/icons-vue";
import { dynamic_config, works_type_config } from "@/common/enum";
import { formatDate } from "@/common/com";
const date_text_config = {
  1: "活动时间",
  2: "上线时间",
  3: "发布时间",
};
export default {
  props: {
    showTitle: {
      default: true,
      type: Boolean,
    },
    data: {
      default: {},
      type: Object,
    },
  },
  components: {
    ArrowRightBold,
    ArrowLeftBold,
  },
  data() {
    return {
      scrollLeft: 0,
      imgListContentWidth: 0,
      imgListContentWrapWidth: 0,
      dynamic_config,
      works_type_config,
      date_text_config,
      formatDate
    };
  },
  created() {
    if(this.data?.images_list?.length > 0){
      this.$nextTick(() => {
      this.imgListContentWidth = this.$refs.imgListRef.offsetWidth;
      this.imgListContentWrapWidth = this.$refs.imgListWrapRef.offsetWidth;
    });
    }
    
  },
  computed: {
    leftBtnDisable() {
      return this.scrollLeft === 0;
    },
    rightBtnDisable() {
      return (
        this.scrollLeft ===
        this.imgListContentWrapWidth - this.imgListContentWidth
      );
    },
    isShowPageBtn() {
      return this.imgListContentWrapWidth > this.imgListContentWidth || true;
    },
  },
  methods: {
    imgClick(index) {
      //由于组件上需要添加一层遮罩点击不到el-image组件，通过el-image组件ref调用元素的click事件
      this.$refs.elImageRef[index]?.$el?.children?.[0]?.click?.();
    },
    carousel(direction) {
      const { offsetWidth: contentWidth } = this.$refs.imgListRef;
      const { offsetWidth: contentWrapWidth } = this.$refs.imgListWrapRef;
      this.imgListContentWidth = contentWidth;
      this.imgListContentWrapWidth = contentWrapWidth;
      const directionFun = {
        left: ({ scrollLeft, contentWidth }) => {
          return Math.max(0, scrollLeft - contentWidth / 2);
        },
        right: ({ scrollLeft, contentWidth, contentWrapWidth }) => {
          return Math.min(
            scrollLeft + contentWidth / 2,
            contentWrapWidth - contentWidth
          );
        },
      };
      const scrollSize = directionFun[direction]({
        scrollLeft: this.scrollLeft,
        contentWidth,
        contentWrapWidth,
      });
      this.scrollLeft = scrollSize;
    },
  },
};
</script>

<style lang="less" scoped>
.wrap {
  padding: 24px 32px;
  .title {
    font-size: 18px;
    color: #222222;
    line-height: 26px;
    display: flex;
    align-items: center;
    > img {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }
  }
  .type {
    display: flex;
    align-items: center;
    margin: 8px 0 16px 0;
    font-size: 14px;
    color: #727272;
    line-height: 22px;
    font-family: PingFangSC-Regular, PingFang SC;
    > span {
      width: 1px;
      height: 12px;
      background: rgba(158, 164, 186, 0.32);
      margin: 0 8px;
    }
  }
  .desc {
    > p {
      font-size: 14px;
      color: #727272;
      line-height: 22px;
    }
    font-size: 14px;
    color: #727272;
    line-height: 22px;
  }
  .date {
    margin-top: 16px;
    font-size: 14px;
    color: #727272;
    line-height: 22px;
  }
  .img_list_page {
    justify-content: flex-end;
    display: flex;
    align-items: center;
    font-family: PingFangSC-Regular, PingFang SC;
    user-select: none;
    margin-bottom: 8px;
    margin-top: -8px;
    .pagination_pre,
    .pagination_next {
      width: 40px;
      height: 28px;
      background: #ffffff;
      border-radius: 2px;
      border: 1px solid #dfdfdf;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      > i {
        font-size: 18px;
        color: rgba(19, 20, 28, 0.9);
        > svg {
          font-size: 18px;
        }
      }
    }
    .pagination_pre {
      margin-right: 8px;
    }
    .pagination_disable {
      cursor: no-drop;
      > i {
        color: #bbbbbd;
      }
    }
  }
}
</style>
<style lang="less">
.img_list {
  position: relative;
  overflow: hidden;
  height: 160px;
  position: relative;
  .img_list_wrap {
    transition: all 1s;
    position: absolute;
    left: 0;
    top: 0;
    white-space: nowrap;
    display: flex;
    width: max-content;
    .img_list_item {
      position: relative;
      // display: inline-block;
      margin-right: 8px;
      border-radius: 6px;
      overflow: hidden;
      flex-shrink: 0;
      min-width: 160px;
      &:hover {
        .img_list_mark {
          display: flex;
        }
      }
      .img_list_mark {
        display: none;
        cursor: pointer;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background: rgba(0, 0, 0, 0.4);
        border-radius: 6px;
        flex-direction: column;
        align-items: center;
        font-size: 12px;
        color: #ffffff;
        > img {
          width: 24px;
          height: 24px;
          margin: 68px 0 4px 0;
        }
      }
      &:last-child {
        margin-right: 0px;
      }
      .el-image > img {
        height: 160px;
        min-width: 108px;
        width: auto
      }
    }
  }
}
</style>
