<template>
  <div class="ip_future_dynamic_wrap" v-loading="listLoading">
    <div class="ip_future_dynamic_content">
      <div
        class="ip_future_dynamic_add"
        @click="openDynamicModal"
      >
        新增动态
      </div>
      <div v-if="dataList.length === 0" class="ip_future_dynamic_noData">
        新增未来动态，预览未来动态
      </div>
      <div
        v-else
        class="ip_future_dynamic_item"
        v-for="item in dataList"
        :key="item.id"
      >
        <div class="ip_future_dynamic_item_title">
          <div class="text">{{ item.name }}</div>
          <div class="edit">
            <span @click="edit(item)">修改</span>
            <el-popconfirm
              confirm-button-text="是"
              cancel-button-text="否"
              title="是否删除?"
              @confirm="deleteDynamic(item.id)"
            >
              <template #reference>
                <span>删除</span>
              </template>
            </el-popconfirm>
          </div>
        </div>
        <futureDynamicCard
          
          :data="item"
          :showTitle="false"
        />
      </div>
    </div>
    <div class="ip_future_dynamic_submit">
      <div @click="linkTo('')">去完善粉丝规模</div>
      <div @click="linkTo('/person/management')">稍后完善</div>
    </div>
    <el-dialog
      width="992px"
      append-to-body
      destroy-on-close
      close-on-click-modal
      v-model="dialogTableVisible"
      :title="isEdit ? '修改动态' : '新增动态'"
      top="80px"
      @close="closeDialog"
    >
      <div class="dialog_content">
        <el-form
          ref="ruleFormRef"
          :model="ruleForm"
          :rules="rules"
          label-width="110px"
          class="dynamic_ruleForm"
          size="large"
          :validate-on-rule-change="false"
        >
          <el-form-item label="动态类型" prop="type">
            <el-radio-group
              @change="changeType"
              class="ip_form_radio"
              v-model="type"
              :disabled="isEdit"
            >
              <el-radio
                v-for="item in DYNAMIC_TYPE"
                :key="item.value"
                :label="item.value"
                >{{ item.label }}</el-radio
              >
            </el-radio-group>
          </el-form-item>
          <template v-if="type === '1'">
            <el-form-item
              class="dynamic_ruleForm_input"
              label="活动名称"
              prop="name"
            >
              <el-input
                class="form_input"
                placeholder="请输入活动名称"
                v-model="ruleForm.name"
              />
            </el-form-item>
            <el-form-item
              class="dynamic_ruleForm_input"
              label="活动时间"
              prop="date"
            >
              <el-date-picker
                v-model="ruleForm.date"
                type="daterange"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                :editable="false"
                format="YYYY-MM-DD"
              />
            </el-form-item>
          </template>
          <template v-else-if="type === '2'">
            <el-form-item
              class="dynamic_ruleForm_input"
              label="作品名称"
              prop="name"
            >
              <el-input
                class="form_input"
                placeholder="请输入作品名称"
                v-model="ruleForm.name"
              />
            </el-form-item>
            <el-form-item label="作品类型" prop="works_type">
              <el-radio-group
                class="ip_form_radio"
                v-model="ruleForm.works_type"
              >
                <el-radio
                  v-for="item in WORKS_TYPE"
                  :key="item.value"
                  :label="item.value"
                  >{{ item.label }}</el-radio
                >
              </el-radio-group>
            </el-form-item>
            <el-form-item
              class="dynamic_ruleForm_input"
              label="上线时间"
              prop="date"
            >
              <el-date-picker
                v-model="ruleForm.date"
                type="daterange"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
              />
            </el-form-item>
          </template>
          <template v-else-if="type === '3'">
            <el-form-item
              class="dynamic_ruleForm_input"
              label="图库名称"
              prop="name"
            >
              <el-input
                class="form_input"
                placeholder="请输入图库名称"
                v-model="ruleForm.name"
              />
            </el-form-item>
            <el-form-item
              class="dynamic_ruleForm_input"
              label="发布时间"
              prop="start_date"
            >
              <el-date-picker
                v-model="ruleForm.start_date"
                placeholder="请选择发布时间"
                type="date"
                :editable="false"
              />
            </el-form-item>
            <el-form-item label="图库风格" prop="images_style">
              <el-input
                :input-style="{ height: '144px' }"
                class="form_input"
                placeholder="请输入图库风格"
                type="textarea"
                v-model="ruleForm.images_style"
                maxlength="200"
                show-word-limit
                resize="none"
              />
            </el-form-item>
          </template>
          <el-form-item label="动态介绍" prop="introduction">
            <el-input
              :input-style="{ height: '144px' }"
              class="form_input"
              placeholder="请输入动态介绍"
              type="textarea"
              v-model="ruleForm.introduction"
              maxlength="500"
              show-word-limit
              resize="none"
            />
          </el-form-item>
          <el-form-item label="合作机会" prop="cooperation_opportunities">
            <el-input
              :input-style="{ height: '144px' }"
              class="form_input"
              placeholder="请输入合作机会"
              v-model="ruleForm.cooperation_opportunities"
              type="textarea"
              maxlength="100"
              show-word-limit
              resize="none"
            />
          </el-form-item>
          <el-form-item
            class="dynamic_ruleForm_input"
            label="招商截止时间"
            prop="end_date_of_attract_investment"
            v-if="type !== '3'"
          >
            <el-date-picker
              v-model="ruleForm.end_date_of_attract_investment"
              placeholder="请选择招商截止时间"
              type="date"
              :editable="false"
            />
          </el-form-item>
          <el-form-item label="封面图片" prop="cover_image">
            <div class="ipLogo">
              <div class="logo">
                <img :src="ruleForm.cover_image" />
              </div>
              <div @click="uploadPicture()">
                <div class="upload">上传</div>
                <p>请上传.png或.jpg图片，图片大小不超过200KB</p>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="动态图片" prop="images_list">
            <div class="images_list_content">
              <el-upload
                action="/api/user/upload-image200"
                :v-model="fileList"
                limit="5"
                :on-success="onFileLoadSuccess"
                :before-upload="beforeUpload"
                accept="image/*"
                multiple
                :on-exceed="onFileLoadExceed"
                :headers="postHeader"
                :show-file-list="false"
                ref="uploadRef"
              >
                <div class="form_images_list_upload_btn">
                  <el-icon :size="20"
                    ><CirclePlusFilled style="color: #f5630b; font-size: 20px"
                  /></el-icon>
                  <span class="form_images_list_upload_btn_text">批量上传</span>
                  <span class="form_images_list_upload_btn_num"
                    >{{ ruleForm.images_list?.length || 0 }}/5</span
                  >
                </div>
              </el-upload>
              <div
                v-for="(imgItem, index) in ruleForm?.images_list"
                class="images_list_content_item"
              >
                <img :src="imgItem" />
                <div
                  @click="delImg(index)"
                  class="images_list_content_item_del"
                >
                  删除
                </div>
              </div>
            </div>
          </el-form-item>
        </el-form>
        <div class="dynamic_ruleForm_submit">
          <div @click="submit">保存</div>
          <div @click="closeDialog">取消</div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="图片裁剪"
      v-model="cropperModel"
      center
      width="90%"
      close-on-click-modal="false"
    >
      <cropper-image
        accept="image/png,image/jpg"
        :config="config"
        :kind="kind"
        @uploadImgSuccess="handleUploadSuccess"
        ref="child"
      >
      </cropper-image>
    </el-dialog>
  </div>
</template>

<script>
import futureDynamicCard from "@/components/futureDynamicCard";
import cropperImage from "@/components/cropperImage.vue";
import { CirclePlusFilled } from "@element-plus/icons-vue";
import { DYNAMIC_ADD_RULES } from "./enum";
import { DYNAMIC_TYPE, WORKS_TYPE } from "@/common/enum";
import { ElMessage } from "element-plus";
import tracker from '@/common/tracker.js';
import dayjs from "dayjs";
import icom from "@/common/com.js";
const format = "YYYY-MM-DD";
export default {
  emits: ["changeActive"],
  components: {
    futureDynamicCard,
    cropperImage,
    CirclePlusFilled,
  },
  data() {
    return {
      type: "1", //动态类型，维护一个状态去配置不同的rules
      fileList: [],
      dataList: [],
      listLoading: false,
      dialogTableVisible: false,
      ruleForm: {
        type: "1",
      },
      id: "",
      rules: {},
      DYNAMIC_TYPE,
      WORKS_TYPE,
      cropperModel: false,
      cropperName: "",
      kind: "",
      config: {
        autoCropWidth: "329",
        autoCropHeight: "190",
      },
      postHeader: {
        myToken: icom.getCookie("myToken"),
      },
      // 新建模式或者编辑模式
      isEdit: false
    };
  },
  computed: {
    rules() {
      return DYNAMIC_ADD_RULES(this.type);
    },
  },
  created() {
    this.ipId = this.$route.query.iid;
    this.getList();
  },
  methods: {
    changeType(value) {
      this.type = value;
      this.ruleForm.type = value;
    },
    delImg(index) {
      this.$refs.uploadRef.handleRemove(this.fileList[index]),
      this.ruleForm.images_list.splice(index, 1);
    },
    async getList() {
      if(!this.ipId) return
      this.listLoading = true;
      try {
        await this.API.get_ip_dynamic({ ipId: this.ipId }, (res) => {
          const { code, data } = res;
          if (code === 0) {
            this.dataList = data;
          }
        });
      } catch (e) {
        ElMessage({
          message: `请求错误`,
          type: "error",
        });
      } finally {
        this.listLoading = false;
      }
    },
    deleteDynamic(id) {
      this.listLoading = false;
      try {
        this.API.del_ip_dynamic({ id }, (res) => {
          const { code } = res;
          if (code === 0) {
            this.getList();
            ElMessage({
              message: `删除成功`,
              type: "success",
            });
          }
        });
      } catch (e) {
        ElMessage({
          message: `请求错误`,
          type: "error",
        });
      } finally {
        this.listLoading = false;
      }
    },
    //封面设置
    uploadPicture() {
      this.cropperModel = true;
    },
    //图片截图成功后
    handleUploadSuccess({ base64 }) {
      this.cropperModel = false;
      this.ruleForm.cover_image = base64;
    },
    onFileLoadSuccess(res) {
      const { code, data, msg } = res;
      if (code === 0) {
        console.log( data,this.ruleForm.images_list)
        this.ruleForm.images_list = [
          data.file,
          ...(this.ruleForm.images_list || []),
        ];
      } else {
        ElMessage({
          message: msg,
          type: "error",
        });
      }
    },
    beforeUpload(file) {
      this.fileList.push(file)
    },
    onFileLoadExceed(file) {
      ElMessage({
          message: `动态图片最多上传5个`,
          type: "warning",
        });
    },
    getPostData(form) {
      const {
        type,
        name,
        introduction,
        cooperation_opportunities,
        end_date_of_attract_investment,
        cover_image,
        images_list,
      } = form;
      const commonData = {
        type,
        name,
        introduction,
        cooperation_opportunities,
        cover_image,
        images_list,
      };
      const dataConfig = {
        1: (form) => {
          const { date } = form;
          return {
            start_date: dayjs(date[0]).format(format),
            end_date: dayjs(date[1]).format(format),
            end_date_of_attract_investment: dayjs(
              end_date_of_attract_investment
            ).format(format),
          };
        },
        2: (form) => {
          const { works_type, date } = form;
          return {
            start_date: dayjs(date[0]).format(format),
            end_date: dayjs(date[1]).format(format),
            works_type,
            end_date_of_attract_investment: end_date_of_attract_investment ? dayjs(
              end_date_of_attract_investment
            ).format(format) : '',
          };
        },
        3: (form) => {
          const { start_date, images_style } = form;
          return {
            start_date: dayjs(start_date).format(format),
            images_style,
          };
        },
      };
      return { ...dataConfig[form.type](form), ...commonData };
    },
    async submit() {
      if (!this.$refs.ruleFormRef) return;
      await this.$refs.ruleFormRef.validate((valid, fields) => {
        if (valid) {
          const data = this.getPostData(this.ruleForm);
          this.API.save_ip_dynamic(
            { ipId: this.ipId, id: this.id, ...data },
            (res) => {
              const { code } = res;
              if (code === 0) {
                this.getList();
                this.closeDialog();
                ElMessage({
                  message: `保存成功`,
                  type: "success",
                });
              }
            }
          );
        }
      });
    },
    edit(item) {
      this.id = item.id;
      const { type, start_date, end_date, end_date_of_attract_investment } =
        item;
      let specialForm = {};
      if(type == 1 || type == 2){
        specialForm['end_date_of_attract_investment'] = dayjs(end_date_of_attract_investment)
      }
      if (type == 1 || type == 2) {
        specialForm["date"] = [dayjs(start_date), dayjs(end_date)];
      } else if (type == 3) {
        specialForm["start_date"] = dayjs(start_date);
      }
      this.type = type;
      this.ruleForm = { ...item, ...specialForm };
      this.isEdit = true;
      this.dialogTableVisible = true;
    },
    closeDialog() {
      this.dialogTableVisible = false;
      this.ruleForm = { type: 1 };
      this.id = "";
    },
    linkTo(url) {
      if (url) {
        tracker({opType:14,ipId: this.ipId})
        this.$router.push(url);
      } else {
        tracker({opType:13,ipId: this.ipId})
        this.$emit("changeActive", "fansSize");
      }
    },
    openDynamicModal(){
      this.dialogTableVisible = true; 
      this.isEdit = false; 
      this.type = '1';
    }
  },
};
</script>

<style lang="less" scoped>
.ip_future_dynamic_wrap {
  min-height: 700px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .ip_future_dynamic_content {
    .ip_future_dynamic_add {
      width: 144px;
      height: 40px;
      background: #ffffff;
      border-radius: 6px;
      border: 1px solid #e3e6ec;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      text-align: center;
      line-height: 38px;
      color: #585d70;
      cursor: pointer;
      user-select: none;
      margin-bottom: 16px;
    }
    .ip_future_dynamic_noData {
      height: 304px;
      background: #ffffff;
      border-radius: 6px;
      border: 1px dashed rgba(158, 164, 186, 0.48);
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      color: #888888;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .ip_future_dynamic_item {
      background: #ffffff;
      border-radius: 6px;
      border: 1px solid #e3e6ec;
      overflow: hidden;
      margin-bottom: 16px;
      .ip_future_dynamic_item_title {
        height: 40px;
        background: #f6f8fc;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .text {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          color: #0b0b0b;
          margin-left: 30px;
        }
        .edit {
          > span {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            color: #ea5504;
            margin-right: 32px;
            cursor: pointer;
            user-select: none;
          }
        }
      }
    }
  }
  .ip_future_dynamic_submit {
    display: flex;
    justify-content: center;
    padding: 48px 0;
    > div {
      width: 256px;
      height: 44px;
      border-radius: 6px;
      border: 1px solid transparent;
      text-align: center;
      line-height: 42px;
      cursor: pointer;
    }
    > div:first-child {
      background: #f5630b;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      color: #ffffff;
      margin-right: 88px;
    }
    > div:last-child {
      border-color: #f5630b;
      background: #ffffff;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      color: #f5630b;
    }
  }
}
.dynamic_ruleForm {
  .dynamic_ruleForm_input {
    width: 360px;
  }
  .images_list_content {
    display: flex;
    flex-wrap: wrap;
    .images_list_content_item {
      width: 192px;
      height: 144px;
      border-radius: 6px;
      border: 1px solid #e3e6ec;
      overflow: hidden;
      margin-right: 16px;
      margin-bottom: 16px;
      position: relative;
      > img {
        height: 100%;
        object-fit: cover;
      }
      .images_list_content_item_del {
        display: none;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 32px;
        line-height: 32px;
        text-align: center;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        color: #ffffff;
        cursor: pointer;
        background: rgba(0, 0, 0, 0.24);
      }
      &:hover {
        .images_list_content_item_del {
          display: block;
        }
      }
    }
  }
}
.dynamic_ruleForm_submit {
  display: flex;
  justify-content: center;
  padding-top: 32px;
  > div {
    width: 256px;
    height: 44px;
    border-radius: 6px;
    border: 1px solid #f5630b;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    color: #ffffff;
    text-align: center;
    line-height: 42px;
    cursor: pointer;
  }
  > div:first-child {
    background: #f5630b;
    margin-right: 88px;
  }
  > div:last-child {
    background: #ffffff;
    color: #f5630b;
  }
}
.ipLogo {
  display: flex;
  align-items: center;
  .logo {
    width: 192px;
    height: 144px;
    background: #ffffff;
    border-radius: 6px;
    border: 1px solid #e3e6ec;
    margin-right: 16px;
    text-align: center;
    img {
      height: 144px;
      max-width: 100%;
      object-fit: cover;
    }
  }
  .upload {
    width: 112px;
    height: 40px;
    line-height: 40px;
    background: #ffffff;
    border-radius: 6px;
    border: 1px solid #e3e6ec;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #585d70;
    text-align: center;
    cursor: pointer;
  }
  p {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #888888;
    line-height: 44px;
  }
}
.dialog_content {
  height: 70vh;
  overflow-y: scroll;
}
.form_images_list_upload_btn {
  width: 192px;
  height: 144px;
  border-radius: 6px;
  border: 1px dashed rgba(138, 145, 157, 0.48);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  .form_images_list_upload_btn_text {
    font-size: 14px;
    color: rgba(19, 20, 28, 0.9);
    margin: 12px 0;
  }
  .form_images_list_upload_btn_num {
    font-size: 14px;
    color: #888888;
  }
}
</style>
