
export const  DYNAMIC_ADD_RULES = (type) => {
  const NAME_TEXT = {
    1:'活动',
    2:'作品',
    3:'新图库'
  }
  const DATE_TEXT = {
    1:'活动',
    2:'上线',
  }
  return {
    type:[
      {
        required: true,
        message: "请选择动态类型",
        trigger: "blur",
      },
    ],
    name:[
      {
        required: true,
        message: `请输入${NAME_TEXT[type]}名称`,
        trigger: "blur",
      },
      {
        max: 50,
        message: `${NAME_TEXT[type]}名称不能超出50个字符`,
        trigger: "blur",
      },
    ],
    date:[
      {
        required: true,
        message: `请选择${DATE_TEXT[type]}时间`,
        trigger: "blur",
      }
    ],
    start_date:[
      {
        required: true,
        message: "请选择发布时间",
        trigger: "blur",
      }
    ],
    works_type:[
      {
        required: true,
        message: "请选择作品类型",
        trigger: "blur",
      }
    ],
    images_style:[
      {
        required: true,
        message: "请输入图库风格",
        trigger: "blur",
      },
      {
        max: 200,
        message: "图库风格不能超出200个字符",
        trigger: "blur",
      },
    ],
    introduction:[
      {
        required: true,
        message: "请输入动态介绍",
        trigger: "blur",
      },
      {
        max: 500,
        message: "动态介绍不能超出500个字符",
        trigger: "blur",
      },
    ],
    cooperation_opportunities:[
      {
        required: true,
        message: "请输入合作机会",
        trigger: "blur",
      },
      {
        max: 100,
        message: "合作机会不能超出100个字符",
        trigger: "blur",
      },
    ],
    end_date_of_attract_investment: type === '1' ? [
      // 当 IP动态类型为作品时，将招商截止时间改为非必填项
       {
        required: true,
        message: "请选择招商截止时间",
        trigger: "blur",
      }
    ]:[],
    cover_image:[
      {
        required: true,
        message: "请选择封面图片",
        trigger: "blur",
      },
    ]
  }
}