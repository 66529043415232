<template>
  <div class="pictureList">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
      <el-form-item label="显示顺序" prop="sort">
        <el-input v-model="ruleForm.sort" type="number" style="max-width: 360px"></el-input>
      </el-form-item>
      <el-form-item label="栏目名称" prop="column_name">
        <el-input v-model="ruleForm.column_name" style="max-width: 360px"></el-input>
      </el-form-item>
      <el-form-item label="图片上传" prop="content">
        <div class="ipLogo">
            <div class="logo" v-for="(item,index) in ruleForm.content" :key="item">
              <img :src="item">
              <div class="delete" @click="deleteImg(index)">删除</div>
            </div>
            <div class="upAll">
              <div class="upload" @click="uploadPicture('horizontal')">上传横图</div>
              <div class="upload" @click="uploadPicture('vertical')">上传竖图</div>
            </div>
        </div>
      </el-form-item>
      <el-form-item>
        <div class="formConfirm">
          <el-button type="primary" @click="submitForm">保存</el-button>
          <el-button @click="cancel">取消</el-button>
        </div>
      </el-form-item>
    </el-form>
    <el-dialog
      title="图片裁剪"
      v-model="cropperModel"
      center
      width='90%'
      :key="index+kind" 
      :close-on-click-modal=false
      >
      <cropper-image
          accept="image/png,image/jpg,image/jpeg"
          :config="config[kind]"
          :kind="index"
          @uploadImgSuccess = "handleUploadSuccess"
          >
      </cropper-image>
    </el-dialog>
  </div>
</template>
<script>
import cropperImage from "@/components/cropperImage.vue";
export default {
		data() {
      return {
        //裁切图片参数
        cropperModel:false,
        index:0,
        kind:'horizontal',
        config:{
          'horizontal':{
            autoCropWidth:"550",
            autoCropHeight:"280"
          },
          'vertical':{
            info:false,
            fixedBox:false
          },
        },
        ruleForm: {
          sort:(this.number+1)*10,
          content:[],
          ...this.ruleFormEdit,
        },
        rules: {
          sort: [
            { required: true, message: '请填写显示顺序', trigger: 'blur' },
          ],
          column_name: [
            { required: true, message: '请填写栏目名称',trigger: 'blur'},
            { max:20,message:'栏目名称不能超过20个字符',trigger: 'blur'}
          ],
          content:[
            { required: true, message: '至少添加一条图片'},
          ]
        }
      };
    },
    props:['ruleFormEdit','number','type','ipId'],
    components: {
      cropperImage
    },
		methods:{ 
			submitForm() {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.API.save_ip_introduction({ipId:this.ipId,...this.ruleForm,type:this.type}, (res) => { 
              const { code,msg } = res
              if(code === 0){
                 this.$emit('ok')
              }else{
                this.$message({
                  type: 'info',
                  message: msg
                });
              }
            })
          }
        });
      },
      cancel(){
        this.$emit('cancel')
      },
      //封面设置
      uploadPicture(kind){
        this.kind = kind
        this.cropperModel = true;
      },
      //图片截图成功后
      handleUploadSuccess({base64}){
        this.cropperModel = false;
        this.ruleForm.content = [...this.ruleForm.content,base64]
      },
      deleteImg(index){
        this.ruleForm.content.splice(index, 1);
      }
		},
	}
</script>
<style lang="less" scoped="" src='./styles.less'>
</style>