<template>
  <div class="pictureList">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
      <el-form-item label="显示顺序" prop="sort">
        <el-input v-model="ruleForm.sort" type="number" style="max-width: 360px"></el-input>
      </el-form-item>
      <el-form-item label="栏目名称" prop="column_name">
        <el-input v-model="ruleForm.column_name" style="max-width: 360px"></el-input>
      </el-form-item>
      <el-form-item label="图片内容" prop="content">
        <editor v-model="ruleForm.content"></editor>
      </el-form-item>
      <el-form-item>
        <div class="formConfirm">
          <el-button type="primary" @click="submitForm">保存</el-button>
          <el-button @click="cancel">取消</el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import editor from '@/components/tinymce/index.vue'
export default {
		data() {
      return {
        ruleForm: {
          sort:(this.number+1)*10,
          content:'',
          ...this.ruleFormEdit,
        },
        rules: {
          sort: [
            { required: true, message: '请填写显示顺序', trigger: 'blur' },
          ],
          column_name: [
            { required: true, message: '请填写栏目名称',trigger: 'blur'},
            { max:20,message:'栏目名称不能超过20个字符',trigger: 'blur'}
          ],
          content:[
            { required: true, message: '请填写图文信息'},
          ]
        }
      };
    },
    props:['ruleFormEdit','number','type','ipId'],
    components: {
      editor
    },
		methods:{ 
			submitForm() {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.API.save_ip_introduction({ipId:this.ipId,...this.ruleForm,type:this.type}, (res) => { 
              const { code,msg } = res
              if(code === 0){
                 this.$emit('ok')
              }else{
                this.$message({
                  type: 'info',
                  message: msg
                });
              }
            })
          }
        });
      },
      cancel(){
        this.$emit('cancel')
      },
		},
	}
</script>
<style lang="less" scoped="" src='./styles.less'>
</style>
<style scoped>
#content {
  width: 100%;
  display: block;
  word-break: break-all;
  word-wrap: break-word;
}
</style>