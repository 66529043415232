<template>
  <div class="pictureList">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
      <el-form-item label="显示顺序" prop="sort">
        <el-input v-model="ruleForm.sort" type="number" style="max-width: 360px"></el-input>
      </el-form-item>
      <el-form-item label="栏目名称" prop="column_name">
        <el-input v-model="ruleForm.column_name" style="max-width: 360px"></el-input>
      </el-form-item>
      <el-form-item label="姓名" prop="content['姓名']" :rules="rules['姓名']">
        <el-input v-model="ruleForm.content['姓名']" style="max-width: 360px"></el-input>
      </el-form-item>
      <el-form-item label="性别" prop="性别">
        <el-radio-group v-model="ruleForm.content['性别']">
          <el-radio label='男'>男</el-radio>
          <el-radio label='女'>女</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-row>
        <el-col :span="12">
          <el-form-item label="国籍" prop="国籍">
            <el-select v-model="ruleForm.content['国籍']" placeholder="请选择" style="width:360px">
                <el-option
                  v-for="item in countryDict"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="城市" prop="城市">
            <el-cascader
              expand-trigger="hover"
              :options="locationDict"
              v-model="ruleForm.content['城市']"
              @change="handleChange"
              style="width:360px"
              placeholder="请选择"
              >
            </el-cascader>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="年龄" prop="content['年龄']" :rules="rules['年龄']">
            <el-input v-model="ruleForm.content['年龄']" style="max-width: 360px" type="number" min="0"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="职业" prop="content['职业']" :rules="rules['职业']">
            <el-input v-model="ruleForm.content['职业']" style="max-width: 360px"></el-input>
          </el-form-item>
        </el-col>
      </el-row>    
      <el-form-item label="籍贯" prop="籍贯">
        <el-select v-model="ruleForm.content['籍贯']" placeholder="请选择" style="width: 360px">
            <el-option
              v-for="item in locationDict"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
        </el-select>
      </el-form-item>
      <el-row>
        <el-col :span="8">
          <el-form-item label="婚姻状况" prop="婚姻状况">
            <el-radio-group v-model="ruleForm.content['婚姻状况']">
              <el-radio label='已婚'>已婚</el-radio>
              <el-radio label='未婚'>未婚</el-radio>
              <el-radio label=''>无</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="16">
          <el-form-item label="子女情况" prop="子女情况">
            <el-radio-group v-model="ruleForm.content['子女情况']">
              <el-radio label='有子女'>有子女</el-radio>
              <el-radio label='无子女'>无子女</el-radio>
              <el-radio label=''>无</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item>
        <div class="formConfirm">
          <el-button type="primary" @click="submitForm">保存</el-button>
          <el-button @click="cancel">取消</el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
		data() {
      return {
        ruleForm: {
          sort:(this.number+1)*10,
          ...this.ruleFormEdit,
        },
        rules: {
          sort: [
            { required: true, message: '请填写显示顺序', trigger: 'blur' },
          ],
          column_name: [
            { required: true, message: '请填写栏目名称',trigger: 'blur'},
            { max:20,message:'栏目名称不能超过20个字符',trigger: 'blur'}
          ],
          姓名:[
            { max:50,message:'姓名不能超出50个字符',trigger: 'blur'}
          ],
          职业:[
            { max:50,message:'职业不能超出50个字符',trigger: 'blur'}
          ],
          年龄:[
            { max:50,message:'年龄不能超出50个字符',trigger: 'blur'}
          ]
        },
        countryDict:[],
        locationDict:[]
      };
    },
    props:['ruleFormEdit','number','type','ipId'],
    components: {
    },
		methods:{ 
			submitForm() {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            if(Object.keys(this.ruleForm.content).length  > 0){
              this.ruleForm.content['城市'] =  this.ruleForm.content['城市'].join(",")
              this.API.save_ip_introduction({ipId:this.ipId,...this.ruleForm,type:this.type,}, (res) => { 
              this.$emit('ok')
              })
            }else{
              this.$message({
                type: 'error',
                message: '请填写至少一个信息'
              });
            }
          }
        });
      },
      cancel(){
        this.$emit('cancel')
      },
      getCountryDict(){
        this.API.country_dict({}, (res) => { 
					const { data} = res
					this.countryDict = data
				})
      },
      getLocationDict(){
        this.API.location_dict({}, (res) => { 
					const { data} = res
					this.locationDict = data
				})
      },
		},
		created(){
      this.getCountryDict()
      this.getLocationDict()
      this.ruleForm.content = {...this.ruleFormEdit?.content}//深拷贝
      if(this.ruleFormEdit.content['城市']){
        this.ruleForm.content['城市'] = this.ruleFormEdit.content['城市'].split(",")
      }
		},
	}
</script>
<style lang="less" scoped="" src='./styles.less'>
</style>