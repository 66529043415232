<template>
  <div class="list" v-loading="loading">
    <ul class="btnGroup">
      <li v-for="item in btnList" :key="item.value" @click="change(item)">{{item.name}}</li>
    </ul>
    <div v-if="!!list.length">
      <div v-for="item in list" :key="item.type">
        <div v-if="item.type === '1'" class="horizontalList base">
          <div class="title">
            <p>{{item.column_name}}</p>
            <div class="operation">
              <span @click="edit(item.type,item)">修改</span>
              <span @click="deleteInfo(item.id)">删除</span>
            </div>
          </div>
          <div class="content" v-for="list in item.content" :key="list.title">
            <img :src="list.image" />
            <div>
              <p class="name">{{list.title}}</p>
              <p class="desc">{{list.content}}</p>
            </div>
          </div>
        </div>
        <div v-if="item.type === '2'" class="base">
          <div class="title">
            <p>{{item.column_name}}</p>
            <div class="operation">
              <span @click="edit(item.type,item)">修改</span>
              <span @click="deleteInfo(item.id)">删除</span>
            </div>
          </div>
          <div v-html="item.content.replace(/[\r\n]/g,'<br />')" style="padding:30px">
          </div>
        </div>
        <div v-if="item.type === '3'" class="base">
          <div class="title">
            <p>{{item.column_name}}</p>
            <div class="operation">
              <span @click="edit(item.type,item)">修改</span>
              <span @click="deleteInfo(item.id)">删除</span>
            </div>
          </div>
          <div class="ban">
            <el-carousel :interval="5000" type="card" height="280px" class="banCont" arrow="never"  ref="cardShow">
              <el-carousel-item v-for="imgList in item.content" :key="imgList">
                <img :src="imgList" class="banImg"/>
              </el-carousel-item>
            </el-carousel>
            <div class="arr arr1" v-show="item.content.length>1" @click="arrLeft(item.type3len)"></div>
            <div class="arr arr2" v-show="item.content.length>1" @click="arrRight(item.type3len)"></div>
          </div>
        </div>
        <div v-if="item.type === '4'" class="baseInfo base">
          <div class="title">
            <p>{{item.column_name}}</p>
            <div class="operation">
              <span @click="edit(item.type,item)">修改</span>
              <span @click="deleteInfo(item.id)">删除</span>
            </div>
          </div>
          <ul>
            <li v-for="list in Object.entries(item.content).filter(v=> v[1] != '')" :key="list[0]">
              <span class="name">{{list[0]}}</span>
              <span>{{[list[1]]?.join("/")}}</span>
            </li>
          </ul>
        </div>
        <div v-if="item.type === '5'" class="horizontalList verticalList base">
          <div class="title">
            <p>{{item.column_name}}</p>
            <div class="operation">
              <span @click="edit(item.type,item)">修改</span>
              <span @click="deleteInfo(item.id)">删除</span>
            </div>
          </div>
          <div class="content" v-for="list in item.content" :key="list.title">
            <img :src="list.image" />
            <div>
              <p class="name">{{list.title}}</p>
              <p class="desc">{{list.content}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="empty">
      <el-empty description="新增IP介绍栏目，预览IP介绍内容"/>
    </div>
    <div class="submit">
      <div class="next" @click="goToFeature">去完善未来动态</div>
      <div class="save"  @click="goToManagement">先保存后完善</div>
      <div class="preview" @click="preview">预览</div>
    </div>
    <el-dialog
      v-model="visible"
      :title="active.name"
      width="1000px"
      @click="visible = false"
      :close-on-click-modal=false
      :destroy-on-close=true
    >
    <component :is="active.value" :ruleFormEdit="active.item" :number="list.length" :type="active.type" @cancel="visible = false" @ok="ok" :ipId="iid"></component>
  </el-dialog>
  </div>
</template>
<script>
import horizontalPicture from './horizontalPicture.vue'
import pictureList from './pictureList.vue'
import pictureText from './pictureText.vue'
import infoCard from './infoCard.vue'
import tracker from '@/common/tracker.js';
export default {
		data() {
      return {
        loading:false,
        list:[],
        btnList:[
          {name:'新增横版图文列表',value:'horizontalPicture',type:'1'},
          {name:'新增竖版图文列表',value:'horizontalPicture',type:'5'},
          {name:'新增图片合集',value:'pictureList',type:'3'},
          {name:'新增图文信息',value:'pictureText',type:'2'},
          {name:'新增资料卡',value:'infoCard',type:'4'},
        ],
        active:{},
        visible:false
      };
    },
    components: {
      horizontalPicture,
      pictureList,
      pictureText,
      infoCard
    },
    props:['iid'],
		methods:{ 
      async getData(){
        this.loading = true;
				await this.API.ip_introduction_list({ipId:this.iid}, (res) => { 
					const { data } = res
					this.list = data;
				})
        this.loading = false;
			},
      change(value){
        this.active = value
        this.visible = true
      },
      edit(type,item){
        const result = this.btnList.filter(v=> v.type === type)
        this.change({...result[0],item})
      },
      deleteInfo(id){
        console.log("deleteInfo")
        this.$confirm('是否删除?', '提示', {
          confirmButtonText: '是',
          cancelButtonText: '取消',
          type: 'warning',
					closeOnClickModal:false
        }).then(() => {
					this.API.del_ip_introduction({id}, (res) => { 
						const { code} = res
            this.$message({
              type: 'success',
              message: '操作成功!'
            });
            this.getData();
				})
        });
      },
      ok(){
        this.visible = false
        this.getData()
      },
      goToFeature(){
        tracker({opType:12,ipId:this.iid})
        this.$emit('changeActive','futureDynamic')
      },
      goToManagement(){
        tracker({opType:14,ipId:this.iid})
        this.$router.push('/person/management')
      },
      preview(){
        tracker({opType:7,ipId:this.iid})
        window.open(`/detail?iid=${this.iid}&preview=01`)
      },
      arrLeft(){
				this.$refs.cardShow[0].prev();
			},
			arrRight(){
				this.$refs.cardShow[0].next();
			},
		},
		created(){
			if(this.iid){
        this.getData()
      }
		},
	}
</script>
<style lang="less" scoped="" src='./styles.less'>
</style>