<template>
  <div class="fans_size_wrap" v-loading="loading">
    <div class="fans_size_content">
      <div class="fans_size_item">
        <div class="fans_size_item_platform">平台</div>
        <div class="fans_size_item_account">官方账号</div>
        <div class="fans_size_item_address">地址</div>
        <div class="fans_size_item_fans">粉丝数</div>
      </div>
      <div
        class="fans_size_item"
        v-for="platform in platformOption"
        :key="platform.value"
      >
        <div class="fans_size_item_platform">
          <img :src="platform.icon" />{{ platform.label }}
        </div>
        <div class="fans_size_item_account">
          <el-input
            class="form_input"
            size="large"
            v-model="platformItemForm[platform.value].account"
          />
        </div>
        <div class="fans_size_item_address">
          <el-input
            class="form_input"
            size="large"
            v-model="platformItemForm[platform.value].link"
          />
        </div>
        <div class="fans_size_item_fans">
          <el-input
            class="form_input"
            size="large"
            v-model="platformItemForm[platform.value].fans_count"
            type="number"
            min="0"
          >
            <template #suffix>
              <span class="fans_size_item_fans_suffix">万</span>
            </template>
          </el-input>
        </div>
      </div>
      <div class="fans_size_submit">
        <button @click="submit('put')">上架IP信息</button>
        <button @click="submit()">稍后上架</button>
      </div>
    </div>
  </div>
</template>

<script>
import { PLATFORM_OPTIONS } from "@/common/enum";
import useStore from "@/store/index.js";
import { ElMessage } from "element-plus";
import tracker from '@/common/tracker.js';
export default {
  data() {
    return {
      platformOption: PLATFORM_OPTIONS,
      platformItemForm: PLATFORM_OPTIONS.reduce((init, item) => {
        init[item.value] = {
          account: "",
          link: "",
          fans_count: "",
        };
        return init;
      }, {}),
      loading: true,
    };
  },
  created() {
    this.store = useStore();
    this.getIpFans();
  },
  methods: {
    async getIpFans() {
      const { iid = "" } = this.$route.query;
      if (!iid) return;
      this.loading = true;

      await this.API.get_ip_fans({ ipId: iid }, (res) => {
        const { code, data = {} } = res;
        if (code === 0) {
          this.platformItemForm = { ...this.platformItemForm, ...data };
        }
      });
      this.loading = false;
    },
    async submit(type) {
      const { iid = "" } = this.$route.query;
      tracker({opType:type === 'put' ? 15 : 14 ,ipId: iid})
      if(type === 'put'){
        const result = this.store.setContract();
				if(!result){
					return
				}
      }
      this.loading = true;
      const checkResult = this.platformOption.map((platform) => {
        const { label, value } = platform;
        const result = this.checkPlatform(this.platformItemForm[value], label);
        return {
          auth: result,
          isEmpty: ["account", "link", "fans_count"].every(
            (v) => !this.platformItemForm[value][v]
          ),
          platform: value,
        };
      });
      const isAuth = checkResult.every((v) => v.auth);
      if (!isAuth) {
        return;
      }
      const fans = checkResult.reduce((init, current) => {
        if (current.isEmpty) {
          return init;
        }
        init = {
          ...init,
          [current.platform]: this.platformItemForm[current.platform],
        };
        return init;
      }, {});
      try {
        const res = await this.API.save_ip_fans({ ipId: iid, fans });
        const { code } = res?.data || {};
        let msg = "";
        if (code === 0) {
          msg = "保存成功";
        } else {
          return;
        }
        if (type === "put") {
          const res = await this.API.on_shelf({ id: iid });
          const { code } = res?.data || {};
          if (code === 0) {
            msg = "上架成功";
          } else {
            return;
          }
        }
        ElMessage({
          message: msg,
          type: "success",
        });
        this.$router.push("/person/management");
      } catch (e) {
      } finally {
        this.loading = false;
      }
    },
    checkPlatform(formItem, label) {
      const checkOption = [
        {
          key: "account",
          checkFun: (value) => {
            if (!value) {
              ElMessage({
                message: `请输入${label}官方帐号`,
                type: "warning",
              });
              return false;
            }
            if (value.length > 20) {
              ElMessage({
                message: `${label}官方帐号不能超出20个字符`,
                type: "warning",
              });
              return false;
            }
            return true;
          },
        },
        {
          key: "link",
          checkFun: (value) => {
            if (!value) {
              ElMessage({
                message: `请输入${label}地址`,
                type: "warning",
              });
              return false;
            }
            if (value.length > 200) {
              ElMessage({
                message: `${label}地址不能超出200个字符`,
                type: "warning",
              });
              return false;
            }
            return true;
          },
        },
        {
          key: "fans_count",
          checkFun: (value) => {
            if (!value) {
              ElMessage({
                message: `请输入${label}粉丝数`,
                type: "warning",
              });
              return false;
            }
            return true;
          },
        },
      ];
      const checkKeys = checkOption.map((v) => v.key);
      const allEmpty = checkKeys.every((v) => !formItem[v]);
      if (allEmpty) {
        return true;
      }
      const checkResult = checkOption.every((item) => {
        return item.checkFun(formItem[item.key]);
      });
      return checkResult;
    },
  },
};
</script>
<style lang="less">
.font() {
  font-size: 14px;
  color: #606060;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
}
.fans_size_wrap {
  .fans_size_content {
    padding-left: 64px;
    .fans_size_item {
      display: flex;
      margin-bottom: 24px;
      > div {
        margin-right: 40px;
      }
      .fans_size_item_platform {
        width: 66px;
        display: flex;
        align-items: center;
        > img {
          width: 16px;
          height: 16px;
          margin-right: 4px;
        }
        .font();
      }
      .fans_size_item_account {
        width: 208px;
        .font();
      }
      .fans_size_item_address {
        width: 384px;
        .font();
      }
      .fans_size_item_fans {
        width: 176px;
        .font();
        .fans_size_item_fans_suffix {
          color: #585d70;
          font-size: 14px;
        }
      }
    }
    .fans_size_submit {
      display: flex;
      justify-content: center;
      padding-top: 24px;
      padding-bottom: 98px;
      > button {
        width: 256px;
        height: 44px;
        font-size: 14px;
        border-radius: 6px;
        cursor: pointer;
      }
      > button:first-child {
        background: #f5630b;
        color: #ffffff;
        margin-right: 88px;
        &:hover {
          background: rgba(245, 99, 11, 0.8);
        }
      }
      > button:last-child {
        border: 1px solid #f5630b;
        color: #f5630b;
        background: transparent;
        &:hover {
          border-color: rgba(245, 99, 11, 0.6);
        }
      }
    }
  }
}
</style>
