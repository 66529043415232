const status = {'1':'re'}
<template>
	<div class="addEdit">
		<div class="breadcrumb">
			<span class="first" @click="goTo">IP管理</span>
			<span class="gt">&gt;</span>
			<span class="second">{{text}}</span>
		</div>
		<ul  class="tabs">
			<li :class="{active:item.id ===  active}" v-for="item in tabs" :key="item.id" @click="change(item.id)">{{item.name}}</li>
		</ul>
		<component :is="active" @changeActive="change" :iid="iid"></component>
	</div>
</template>
<script type="text/javascript">
	import baseInfo from './baseInfo.vue';
	import fansSize from './fansSize.vue';
	import futureDynamic from './futureDynamic.vue'
	import introduce from './introduce/index.vue'
	export default {
		data(){
			return {
				tabs:[{id:'baseInfo',name:'填写IP基础信息'},
				{id:'introduce',name:'完善IP介绍内容'},
				{id:'futureDynamic',name:'完善IP未来动态'},
				{id:'fansSize',name:'完善粉丝规模'}],
				active:'baseInfo'
			}
		},
		components:{
			baseInfo,
			fansSize,
			futureDynamic,
			introduce
		},
		methods:{ 
			goTo(){
				this.$router.push('/person/management')
			},
			change(id,iid){
				//iid有值代表：此ip是进行编辑或填完了ip基础信息
				if(this.iid || iid){
					this.active = id
					if(iid){
						this.iid = iid
					}
				}else{
					this.$message({
						type: 'info',
						message: '请填写IP基础信息!'
					});
				}
			}
		},
		created(){
			this.iid = this.$route.query.iid || '';
			this.text = this.iid ? '修改IP':'新增IP'
		},
	}
</script>
<style lang="less" scoped="" src='./styles.less'>
	
</style>